<style>

.row {
    display: inline-flex;
    width: 100%;
    gap: 8px;
}

.column {
    flex: 33.33%;
    gap: 8px;
}

.iframe-wrapper {
    position: relative;
    padding-top: 56.25%;
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>

<template>

<article>
    <div>
        <h1>{{ t('about.title') }}</h1>

        <img src="../assets/about.png" alt="About image" />

        <p>{{ t('about.1-1') }} <a href="https://yannickpulver.com" target="_blank">Yannick</a>. {{ t('about.1-2') }}</p>
        <p>{{ t('about.2') }}</p>
        <p>{{ t('about.3') }}</p>
        <p>{{ t('about.4') }} <a href="https://www.google.com/maps/d/u/0/edit?mid=1AGABaPMcUpvbMqavAUIK_CViRZ_kxWHl&usp=sharing" target="_blank">Google My Map</a>.</p>
        <p>{{ t('about.5') }} <a href="https://youtu.be/apjVCmUzlAA" target="_blank">Video</a></p>
        <p>{{ t('about.6') }}</p>
        <p>{{ t('about.7') }} <a href="mailto:yannick.pulver@gmail.com" target="_blank">yannick.pulver@gmail.com</a> — Instagram: <a href="https://instagr.am/yannickpulver" target="_blank">@yannickpulver</a></p>

        <p>🤷‍♂️</p>
        <br />
        <br />

        <h3>Poster</h3>
        <img src="../assets/poster.png" alt="Poster image" />
        <p>{{ t('poster.1') }}</p>
        <p>{{ t('poster.2') }}</p>
        <p>{{ t('poster.3') }}</p>
        <p>{{ t('poster.4') }}</p>
        <p>{{ t('poster.5') }} <a href="https://blaumacher.be/" target="_blank">Blaumacher Siebdruck Bern</a></p>

        <p>👉 <a href="https://lepulver.studio/products/strassen-in-bern" target="_blank">Link to the shop</a></p>


        <br />
        <br />

        <h3 id="video">Video</h3>
        <p>{{ t('video.1') }}</p>

        <div class="iframe-wrapper" style="">
            <iframe src="https://www.youtube.com/embed/apjVCmUzlAA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <p>👉 <a href="https://youtu.be/apjVCmUzlAA" target="_blank">Video on YouTube</a></p>

        <br />
        <br />


        <h3>NFTs</h3>
        <div class="row">
            <div class="column"><img src="../assets/walk-1.gif" alt="NFT Walk 1" /></div>
            <div class="column"><img src="../assets/walk-25.gif" alt="NFT Walk 25" /></div>
            <div class="column"><img src="../assets/walk-61.gif" alt="NFT Walk 61" /></div>
        </div>
        <p>{{ t('nft.1') }}</p>
        <p>{{ t('nft.2') }}</p>
        <p>👉 <a href="https://opensea.io/collection/walked-be" target="_blank">🌈 NFTs on OpenSea</a></p>

    </div>
</article>

</template>

<script>

import { useI18n } from 'vue-i18n'

export default {
    name: 'About',
    setup() {
      const { t } = useI18n({
        inheritLocale: true
      })
      return { t }
    }
}

</script>
