<style>

p {
    font-weight: 700;
    font-size: 12px;
}

.button {
    position: fixed;
    top: 0;
    left: 0;
}

.walk {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 4;
}

.walk-container {
    background: #fff;
    /* padding: 10px; */
}

.walk-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
}

.walk-navigation button {
    background: #fff;
    border-radius: 20px;
    padding: 7px 13px;
    display: inline-block;
    margin: 10px 0;
}

.walk-navigation button {
    /* background: none; */
    border: 0;
    cursor: pointer;
}

.background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: 0.3;
    z-index: 3;
}

.info {
    display: flex;
    justify-content: space-around;
}

.full {
    width: 100%;
}

.right {
  display: flex;
  justify-content: end;
}

.left {
  display: flex;
  justify-content: start;
}

@media only screen and (max-width: 900px) {
    .walk {
        position: absolute;
        top: 50%;
        width: 100%;
        /* padding: 10px; */
    }
}

</style>

<template>

<div class="background" v-on:click="onClose" />



<div class="walk">
    <div class="walk-navigation" @click="onClose">
        <button @click="onClose">❌</button>
    </div>
    <div class="walk-container">
        <Video :walk="walk" @onUpdate="onUpdateVideo" />
        <div class="info">
            <p>🚶 No. {{ walk.number }}</p>
            <p>🗓 {{ walk.date }}</p>
            <p v-if="videoInfo !== null">🎥 {{ videoInfo.progress }}%</p>
            <p v-if="walk.tags !== undefined">
                <span v-if="walk.tags.includes('gf')" title="This walk is joined by Gia Han!">💃🏻</span>
                <span v-if="walk.tags.includes('reversed')" title="This walk is looking in reverse, hope you don't get see sick!">◀️</span>
            </p>
            <Opensea :id="walk.opensea_id" />
        </div>
    </div>
    <div class="walk-navigation">
        <div @click="onBackward">
            <button @click="onBackward">👈</button>
        </div>
        <span>|</span>
        <div @click="onForward">
            <button @click="onForward">👉</button>
        </div>

    </div>
</div>

</template>

<script>

import Video from './Video.vue'
import Opensea from './Opensea.vue'

import {
    useKeypress
}
from 'vue3-keypress'

export default {
    name: 'Walk',
    components: {
        Video,
        Opensea
    },
    data() {
        return {
            videoInfo: null
        }
    },
    props: {
        walk: {
            type: Object,
            default: null
        },
        close: Function
    },
    setup(props, context) {
        const backward = ({
            event
        }) => {
            context.emit('backward', props.walk.number);
        };

        const forward = ({
            event
        }) => {
            context.emit('forward', props.walk.number);
        };

        const close = ({
            event
        }) => {
            context.emit('close');
        };

        useKeypress({
            keyEvent: "keydown",
            keyBinds: [{
                keyCode: "left", // or keyCode as integer, e.g. 37
                success: backward
            }, {
                keyCode: "right", // or keyCode as integer, e.g. 39
                success: forward
            }, {
                keyCode: "esc", // or keyCode as integer, e.g. 39
                success: close
            }]
        })
    },
    methods: {
        onClose() {
                this.$emit('close')
            },
            onUpdateVideo(info) {
                //console.log("updated", info)
                this.videoInfo = info;
            },
            onForward() {
                //console.log("forward");
                this.$emit('forward', this.walk.number);
            },
            onBackward() {
                //console.log("backward");
                this.$emit('backward', this.walk.number);
            }
    },
    watch: {
        walk(value, oldValue) {
            //console.log("walk in Walk")
        }
    }
}

</script>
