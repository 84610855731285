export default {
  "about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🤷‍♂️ About"])},
  "about.1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi there. My name is"])},
  "about.1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the end of January 2021 I had the idea to \"walk through every street in Bern, Switzerland\"."])},
  "about.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I got very enthusiastic about it and embarked only about 48 hours later. In fact, I was scared that all the thoughts would kick in why this why this idea is weird and stupid."])},
  "about.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It took a total of 239 days until I finished my goal of \"Walking every street in Bern\". In fact, I have walked a total of 436.76km in 77.4 hours over a total of 107 walks. The well-informed reader may notice that there is something missing! I have left out the sixth part of the city (Bümpliz-Oberbottigen), to give this project an ending. But maybe I'll make up for that at some point. Also, my goal was to walk all the streets where cars are also able to drive on. Therefore, the tiny paths &amp; streets between houses were only taken by chance."])},
  "about.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to track all of the streets that I walked, I've used a Google My Map. It's a different map than what is used on this website to show it, therefore some streets may be different. You can find it here:"])},
  "about.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's way more information and I'm talking about all of that in the video I created:"])},
  "about.6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I spent a huge amount of time getting this project together (I don't know why either). If you want to share it with somebody, I'd be super grateful! Pre-high-five! 🙏"])},
  "about.7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions about the project or literally anything else, mail me: "])},
  "poster.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many streets in the city of Bern. But at least 869, because that's how many are on this poster. I created this poster mostly for myself — for reminding me about this fun project, but since there are now a few more pieces - they are now available here for sale! :)"])},
  "poster.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of pieces: Edition of 35"])},
  "poster.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper: Muscat paper 290g/m², 100% recycled paper"])},
  "poster.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print type: Silkscreen"])},
  "poster.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printed via"])},
  "video.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the video I'm explaining the whole project in detail! Grab yourself a cappuccino or water and have a watch ;)"])},
  "nft.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project you’ve never asked for! I made them mostly for fun & education — but there you go! Some of them even come with perks as free pizza or a free poster!"])},
  "nft.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you decide to buy one, your name will be shown next to the walk on the website. But actually, the gas fee is usually just too expensive, so feel free to just look at them or not :D"])},
  "stats.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As the following chart may tell you, I could have easily ended the project 1-2 months earlier. But some other things got in the way. And so it streched to all 4 seasons, which is nice too! It may not look like a lot of time, but most of the walks were rather short (30-45min) and it always included preparing the routes beforehand and filming it all :)"])},
  "stats.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funny enough, on Tuesdays there are a lot of meetings as well as free lunch 🍕 in the company that I'm working. That's probably the reason why there were not a lot of walks done on Tuesdays."])},
  "stats.costs.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
  "stats.costs.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making this project happen was not quite cheap. It sounds it's a free activity to do, but since I wanted to document that journey aswell here are some costs that appeared:"])},
  "stats.costs.1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoPro Backpack Mount (without that, it would not have been as watchable as it is)"])},
  "stats.costs.1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoes (they had holes in them after I finished)"])},
  "stats.costs.1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External hard drive (Since it got to be about 2,5 TB of footage + exports, I needed a new one)"])},
  "stats.costs.1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public transport (the further I went into this project, the further I had to travel to get to the starting point of the walk. And since I couldn't always walk and additional hour, I took public transport frequently.)"])},
  "stats.costs.1-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printing cost (well, I didn't have to do the poster, but it's the perfect project that will remind me for years about this lovel walks)"])},
  "stats.costs.1-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small things here and there, Music for the video, etc."])},
  "stats.costs.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So yeah, all of that added up to over ~ 1000 CHF. Was it worth it? Yes absolutely! Just the filmed part of the walking alone took about 75 hours. I expect I invested about 400-500 hours for the whole project (maybe more, haha). But being able to use such a variety of my skills in a single project was epic — so at some point I couldn't hold back!"])},
  "map.tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ℹ️ Click on a path to see the video."])}
}