<style>

.basemap {
    width: 100%;
    height: 100%;
}

.mapboxgl-popup-content {
    background: #000 !important;
    color: #fff !important;
    padding: 5px 8px 5px !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: #000 !important;
}

.marker {
    background-image: url('/circle.svg');
    background-size: cover;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.theme-button {
    position: fixed;
    top: 22px;
    right: 16px;
    z-index: 1;
}

.basemap .mapboxgl-ctrl-bottom-right, .basemap .mapboxgl-ctrl-bottom-left {
  z-index: 5;
}

</style>

<template>

<div id="mapContainer" class="basemap" />

<ThemeButton @onChange="onThemeChange" class="theme-button" />

</template>

<script>

import mapboxgl from "mapbox-gl";
import Walked from '../assets/walked.json';
import ThemeButton from "./ThemeButton.vue";

import {
    getFirestore, doc, setDoc
}
from "firebase/firestore";

export default {
    name: 'Map',
    components: {
        ThemeButton
    },
    data() {
        return {
            accessToken: "pk.eyJ1IjoieWFudSIsImEiOiJja3llOTZvbDAxNnByMnBwYnNpazdoOXQ2In0.knyyiId8_cyCszKUQqgaog",
            walkedGeoJson: Walked,
            map: null
        }
    },
    props: {
        select: Function
    },
    mounted() {
        mapboxgl.accessToken = this.accessToken;

        const bounds = [
            [7.356342805672281, 46.91359318659783], // Southwest coordinates
            [7.532828662072902, 47.003123285258724] // Northeast coordinates
        ];

        // check which style to load
        //const hasDarkPreference = window.matchMedia("(prefers-color-scheme: dark)").matches;
        const hasDarkPreference = false
        let style = getMapStyle(hasDarkPreference)

        var map = new mapboxgl.Map({
            container: "mapContainer",
            style: style,
            center: [7.4440784, 46.9505852],
            zoom: 13,
            minZoom: 12,
            maxBounds: bounds,
            attributionControl: false
        });

        map.addControl(new mapboxgl.AttributionControl({
          compact: false
        }));

        map.on('load', () => {
            // Add a circle layer
            addLayers(map, hasDarkPreference)
        });

        let hoveredIds = [];
        // Create a popup, but don't add it to the map yet.
        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });


        map.on('click', 'walked-hover-area', (e) => {
            let data = getWalkByEvent(e, map, this.walkedGeoJson)
            this.$emit('select', data)
        });

        map.on('mousemove', 'walked-hover-area', (e) => {
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            const featureIds = getFeatureIdsWithNumber(map, e.features[0].properties.number);

            if (featureIds.length > 0) {
                setHovered(false, map, hoveredIds);
                hoveredIds = featureIds;
                setHovered(true, map, hoveredIds);
            }

            let text = "<b>No." + e.features[0].properties.number + "</b>"
            popup.setLngLat(e.lngLat).setHTML(text).addTo(map);
        });

        map.on('mouseleave', 'walked-hover-area', () => {
            map.getCanvas().style.cursor = '';
            setHovered(false, map, hoveredIds);
            hoveredIds = [];
            popup.remove();
        });

        map.on('mousemove', 'markers', (e) => {
            map.getCanvas().style.cursor = 'pointer';
            let text = "<b>⭐️ Starred Spot</b>"
            popup.setLngLat(e.lngLat).setHTML(text).addTo(map);
        });

        map.on('mouseleave', 'markers', () => {
            map.getCanvas().style.cursor = '';
            popup.remove();
        });
        this.map = map;
    },
    methods: {
        onThemeChange(isDark) {
            this.map.setStyle(getMapStyle(isDark));
            this.map.on('style.load', () => {
                cleanMap(this.map);
                addLayers(this.map, isDark);
            });

        }
    }
}

function getMapStyle(isDark) {
    if (isDark) {
        return 'mapbox://styles/yanu/ckzymorvo009v14ng8d29g90d';
    } else {
        return 'mapbox://styles/yanu/ckydflwjpa5e114nuymynwwtr';
    }
}

function cleanMap(map) {
    if (map.getLayer('walked') !== undefined) {
        map.removeLayer('walked');
    }
    if (map.getLayer('walked-focused') !== undefined) {
        map.removeLayer('walked-focused');
    }
    if (map.getLayer('walked-hover-area') !== undefined) {
        map.removeLayer('walked-hover-area');
    }
    if (map.getLayer('markers') !== undefined) {
        map.removeLayer('markers');
    }
    if (map.getSource('walked') !== undefined) {
        map.removeSource('walked');
    }
    if (map.getSource('markers') !== undefined) {
        map.removeSource('markers');
    }
}

function addLayers(map, darkTheme) {
    map.addSource('walked', {
        type: 'geojson',
        data: '/walked.geojson',
        generateId: true
    });
    map.addSource('markers', {
        type: 'geojson',
        data: '/markers.geojson',
        generateId: true
    });
    map.addLayer({
        'id': 'walked',
        'type': 'line',
        'source': 'walked',
        'layout': {
            'line-join': 'round',
            'line-cap': 'round'
        },
        'paint': {
            'line-color': darkTheme ? '#bbb' : '#000',
            'line-width': 1.5
        }
    });

    map.addLayer({
        'id': 'walked-focused',
        'type': 'line',
        'source': 'walked',
        'layout': {
            'line-join': 'round',
            'line-cap': 'round'
        },
        'paint': {
            'line-color': '#f00',
            'line-width': 3,
            'line-opacity': [
                'case', ['boolean', ['feature-state', 'hover'], false],
                1,
                0
            ]
        }
    });

    map.addLayer({
        'id': 'walked-hover-area',
        'type': 'line',
        'source': 'walked',
        'layout': {
            'line-join': 'round',
            'line-cap': 'round'
        },
        'paint': {
            'line-color': '#f00',
            'line-width': 10,
            'line-opacity': 0
        }
    });

    map.addLayer({
        'id': 'markers',
        'type': 'circle',
        'source': 'markers',
        'paint': {
            'circle-radius': 4,
            'circle-color': darkTheme ? '#FFDF00' : '#FFDF00',
            'circle-stroke-color': darkTheme ? '#fff' : '#000',
            'circle-stroke-width': 1,
            'circle-opacity': 0.7
        }
    });
}

function getWalkByEvent(e, map, geoJson) {
    const number = e.features[0].properties.number;
    const coordinates = getCoordinatesForNumber(geoJson, number)
    const progress = calcPercentage(coordinates, [e.lngLat.lng, e.lngLat.lat], map)

    let data = {
        name: e.features[0].properties.name,
        date: e.features[0].properties.date,
        number: e.features[0].properties.number,
        progress: progress
    }
    return data;
}

function getFeatureIdsWithNumber(map, number) {
    // Get all features with id
    const rawFeatures = map.querySourceFeatures('walked', {
        filter: ['==', 'number', number]
    }).map((x) => x.id);
    // Removing duplicates
    var featureIds = [...new Set(rawFeatures)];
    return featureIds;
}


function setHovered(hover, map, ids) {
    ids.forEach(id => {
        map.setFeatureState({
            source: 'walked',
            id: id
        }, {
            hover: hover
        });
    });
}

function getCoordinatesForNumber(geoJson, number) {
    return geoJson.features.filter(x => x.properties.number == number).map(x => x.geometry.coordinates)
}

function calcDistance(x, y) {
    var first = Math.sqrt(Math.abs(x[0] - y[0]))
    var second = Math.sqrt(Math.abs(x[1] - y[1]))
    var result = first + second
    return result
}

function calcPercentage(coordinates, point, map) {
    const lastCoordinates = coordinates.map(x => x[x.length - 1]);
    const cleanedCoordinates = (typeof coordinates[0][0] == 'number') ? coordinates : coordinates.flat()
    const coordinateDiviations = cleanedCoordinates.map(x => calcDistance(x, point));

    var distance = 0
    var minDeviation = 0
    var minDist = 0
    var minIndex = 0

    cleanedCoordinates.forEach((x, i) => {
        if (i < cleanedCoordinates.length - 1 && !lastCoordinates.includes(x)) {
            // Distance between lines
            var lineDist = calcDistance(x, cleanedCoordinates[i + 1])
            var pointDists = coordinateDiviations[i] + coordinateDiviations[i + 1]

            var curDeviation = Math.abs(pointDists - lineDist)
            if (curDeviation < minDeviation || minDeviation == 0) {
                minDeviation = curDeviation
                minDist = distance + coordinateDiviations[i]
                minIndex = i
            }
            distance = distance + lineDist
        }
    })

    const percentage = 100 / distance * minDist
    return percentage
}

</script>
