<style>

.map-info {
    position: fixed;
    bottom: 24px;
    right: 0px;
    z-index: 2;
    background: #000;
    color: #fff;
    padding: 8px 8px;
}

.map-info p {
    margin: 0;
}

</style>

<template>

<Map @select="onSelect" class="map" />


<div v-if="walk !== null">
    <Walk :walk="walk" @close="onClose" @forward="onForward" @backward="onBackward" />
</div>

<div class="map-info">
  <p>{{ t('map.tip') }}</p>
</div>

</template>

<script>

import { useI18n } from 'vue-i18n'
import Map from '../components/Map.vue'
import Walk from '../components/Walk.vue'
import {
    getFirestore, doc, getDoc, setDoc
}
from "firebase/firestore";

export default {
    name: 'Home',
    components: {
        Map,
        Walk
    },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true
      })
      return { t }
    },
    data() {
        return {
            walk: null
        }
    },
    mounted() {
      //console.log(this.id);
      if (this.id != null && this.id != "") {
        //console.log("Props id -->" + props.id)
        this.onSelect({
              "number": this.id,
              "progress": 0
          });
      }
    },
    methods: {
        async onSelect(walk) {
                const db = getFirestore();
                const docRef = doc(db, "walks", walk.number.toString());
                const docSnap = await getDoc(docRef);
                const data = docSnap.data();

                //console.log("hello", walk, data);
                const video_progress = {
                    "video_progress": (data.video_length / 100 * walk.progress).toFixed(0)
                }
                this.walk = {...walk, ...data, ...video_progress}

                // updates url to match
                history.replaceState({}, "Walk " + walk.number, "/" + walk.number)
            },
            onClose() {
                this.walk = null
                // updates url to match
                history.replaceState({}, "walked.be", "/")
            },
            onForward(number) {
                this.onSelect({
                    "number": number + 1,
                    "progress": 0
                });
            },
            onBackward(number) {
                this.onSelect({
                    "number": number - 1,
                    "progress": 0
                });
            }
    }
}


// init data
/* for (const feature of Walked.features) {
  let data = {
      name: feature.properties.name,
      date: feature.properties.date,
      number: feature.properties.number
  }
  //const docSnap = await getDoc(docRef);
  console.log(data);
  await setDoc(doc(db, "walks", data.number.toString()), data, { merge: true });
} */

</script>
