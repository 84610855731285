export default {
  "about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🤷‍♂️ About"])},
  "about.1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey! Mein Name ist"])},
  "about.1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende Januar 2021 hatte ich die Idee, einmal durch jede Strasse in Bern, Schweiz zu laufen."])},
  "about.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich fand diese Idee irgendwie so toll, dass ich mich nicht einmal 48 Stunden später in dieses Abenteuer stürzte. Es gab genügend Gründe warum ich dies lieber nicht tun soll, deshalb wollte ich gleich starten — bevor diese Gedanken auftauchen."])},
  "about.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es dauerte schlussendlich rund 239 Tage bis ich mein Ziel erreicht hatte. Dabei bin ich rund 436.76km in 77.4 Stunden durch die Strassen gelaufen, verteilt über 107 \"Walks\". Die Gut-Informierten unter euch werden bemerkt haben, dass da etwas fehlt. Ich habe mich dazu entschlossen, den sechsten Stadtteil (Bümpliz-Oberbottigen) auszulassen, um dem Projekt auch ein Ende geben zu können. Aber vielleicht werde ich das mal noch nachholen. Zudem war mein Ziel, alle Strassen zu belaufen, auf welchen auch Autos fahren können. Deshalb hatte ich die kleinen Strässli und Sackgassen zwischen den Häusern nur zufällig durchlaufen"])},
  "about.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um das ganze Projekt festzuhalten, habe ich eine Google My Map verwendet. Es war eine andere Karte, als jene welche sich auf der Website befindet. Deshalb kann es sein, dass einige Strassen anders abgebildet werden. Die ursprüngliche Karte kann hier angeschaut werden:"])},
  "about.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt noch viel mehr Informationen in dem Video, welches ich über das Projekt erstellt habe:"])},
  "about.6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Projekt war einiges an Arbeit (Irgendwann konnte ich nicht mehr stoppen). Falls du das Projekt gerne mit jemandem teilen möchtest, wäre das genial! High five 🙏"])},
  "about.7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls du irgendwelche Fragen hast über das Projekt oder auch irgendetwas anderes, sende mir ein Mail:"])},
  "poster.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt viele Strassen in Bern. Mindestens aber 869, denn so viele sind auf diesem Plakat. Ich habe dieses Plakat hauptsächlich für mich selbst gemacht - um mich an dieses tolle Projekt zu erinnern, aber da es jetzt ein paar mehr Stücke gibt - sind jetzt auch ein paar hier zum Verkauf erhältlich :)"])},
  "poster.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stückzahl: Edition of 35"])},
  "poster.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papier: Muskatpapier 290g/m², 100% Altpapier"])},
  "poster.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druckart: Siebdruck"])},
  "poster.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedruckt via"])},
  "video.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dem Video erkläre ich das Projekt in Detail. Nimm ein Cappuccino, Wasser oder sonst etwas und schau es dir an ;)"])},
  "nft.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Projekt, das du dir nie gewünscht hast! Ich habe die NFT's hauptsächlich zum Spass gemacht, aber ja. Einige von den NFT's kommen sogar mit Benefits wie gratis Pizza oder einem gratis Poster!"])},
  "nft.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du dich dazu entscheidest, eines zu kaufen, wird dein Name beim Walk auf der Website angezeigt. Aber ganz ehrlich; die Gas Fee ist oftmals einfach zu teuer; deshalb auch einfach mal anschauen ist easy :D oder auch nicht!"])},
  "stats.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgende Tabelle zeigt, dass ich das Projekt auch etwa 1-2 Monate früher hätte beenden können. Aber wie üblich, gab es andere, wichtigere Dinge ab und zu. Aber so hat sich das Projekt sogar über alle 4 Jahreszeiten erstreckt, was auch schön ist. Es scheint so, als es eigentlich nicht viel Zeit war, aber die meisten Walks waren eher kurz (30-45min) und beinhalteten immer noch das Planen, Verwalten und Filmen."])},
  "stats.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lustigerweise gibt es am Dienstag bei uns im Büro immer gratis Mittagessen 🍕. Deshalb habe ich wohl nicht viele Walks gemacht am Dienstag."])},
  "stats.costs.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten"])},
  "stats.costs.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Projekt als Ganzes war nicht ganz günstig. Es klingt wie eine gratis Aktivität, aber da ich alles noch dokumentieren wollte, gab es hin und wieder ein paar Kosten:"])},
  "stats.costs.1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoPro Rucksack Mount (ohne diesen wären die Videos nicht anschaubar)"])},
  "stats.costs.1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schuhe (das Paar, welches ich gebraucht habe hat jetzt ein paar Löcher.)"])},
  "stats.costs.1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Harddisks (Es sind etw 2,5 TB Videomaterial + finale Videodateien, deshalb musste ich eine neue kaufen)"])},
  "stats.costs.1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÖV (Je weiter das Projekt foranschritt, desto weiter musste ich teilweise reisen bis zum Startpunkt. Und da ich nicht bereits eine Stunde zum Startpunkt laufen wollte, nahm ich ab und zu den ÖV.)"])},
  "stats.costs.1-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druckkosten (Ich hätte das Poster nicht machen müssen, aber es wird mich für Jahre hinweg immer wieder an die tollen Walks erinnern, die ich tätigen durfte)."])},
  "stats.costs.1-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleine Dinge hier und da."])},
  "stats.costs.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles in allem schon rund ~1000 CHF. War es das wert? Absolut! Das Filmen alleine beanspruchte rund 75 Stunden. Ich denke schlussendlich hatte ich rund 400-500 Stunden für alles investiert (vielleicht auch mehr). Noch nie hatte ich ein solch grosses Projekt, wo ich viele verschiedene meiner Skills einsetzen konnte. Irgendwann gab es kein Stoppen mehr ;)"])},
  "map.tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ℹ️ Klicke auf einen Weg um das Video zu sehen."])}
}