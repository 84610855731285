<style>

:root {
    --background-color-primary: #ebebeb;
    --background-color-secondary: #fafafa;
    --accent-color: #cacaca;
    --shadow: rgba(255, 255, 255, 255);

    --text-primary-color: #222;
    --element-size: 3rem;
}


/* Define styles for the root window with dark - mode preference */

:root.dark-theme {
    --background-color-primary: #1e1e1e;
    --background-color-secondary: #2d2d30;
    --shadow: rgba(0, 0, 0, 255);

    --accent-color: #3f3f3f;
    --text-primary-color: #fff;
}


@font-face {
  font-family: "Avenir-DemiBold";
  src: local("Avenir-DemiBold"),
   url(/fonts/avenir/AvenirNext-DemiBold-03.ttf) format("truetype");
}

#app {
    font-family: "Avenir-DemiBold", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: 100%;
}

html {
  height: 100%;
  width: 100%;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
}

header {
    position: fixed;
    z-index: 2;
}

header .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.menu ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0 16px;
}

.menu li {
    display: contents;
    list-style: none;
}

.menu a,
.menu .unavailable {
    padding: 5px 10px;
    background: #000;
    color: #fff;
    font-weight: 800;
    margin-bottom: 12px;
    text-decoration: none;
    transition: all 100ms ease-in-out;
}

.menu a:hover {
    background: #fff;
    color: #000;
    transform: rotate(-10deg);
}

.menu .unavailable {
    cursor: url('~@/assets/icons/ic_eyes.png') 0 0, pointer;
    /* Legacy */
    cursor: url('~@/assets/icons/ic_eyes.png') 0 0, pointer;
    /* FF */
    cursor: -webkit-image-set(url('~@/assets/icons/ic_eyes.png') 1x, url('~@/assets/icons/ic_eyes.png') 2x) 0 0, pointer;
    /* Webkit */
}

.unavailable::after {
    content: "soon!";
    color: #000;
    background: #fff;
    position: absolute;
    font-size: 8px;
    padding: 2px 4px;
    margin-top: -11px;
    margin-left: -10px;
    box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 15%);
}

.logo {
    width: auto;
    height: 40px;
}

.bmc img {
    height: 30px;
}

.menu-button {
    margin-right: 8px;
    background: #000;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.logo-container {
    display: flex;
}

.logo-button {
    height: 40px;
}

article {
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
    padding: 100px 0;
    text-align: start;
}

article img {
    width: 100%;
}

p {
  letter-spacing: 0px;
}

@media only screen and (min-width: 960px) {
    .menu-button {
        display: none;
    }
}

@media only screen and (max-width: 959px) {
    .hideMenu {
        display: none;
    }
}

</style>

<template>

<header>
    <div class="top">
        <div class="logo-container">
            <div class="menu-button" @click="toggleMenu"><img src="./assets/icons/menu_white_24dp.svg" alt="Navigation" /></div>

            <a href="/" class="logo-button">
                <img src="./assets/logo.png" alt="Logo" class="logo" />
            </a>
        </div>
    </div>

    <nav class="menu" :class="{hideMenu: hideMenu}">
        <ul>
            <li><a href="/about">🤷‍♂️ About</a></li>
            <li><a href="/stats">📈 Stats</a></li>
            <li><a href="https://youtu.be/apjVCmUzlAA" target="_blank">🎥 Video</a></li>
            <li><a href="https://lepulver.studio/products/strassen-in-bern" target="_blank">🖼 Poster</a></li>
            <li><a href="https://opensea.io/collection/walked-be" target="_blank">🌈 NFTs</a></li>
            <li><a href="https://buymeacoffee.com/yannickpulver" target="_blank">☕️ Coffee?</a></li>
        </ul>
    </nav>
</header>



<router-view />

</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            hideMenu: true,
            released: true,
        }
    },
    methods: {
        toggleMenu() {
            this.hideMenu = !this.hideMenu;
        }
    }
}

</script>
