<style>

.player-container {
    position: relative;
}

.video {
    object-fit: cover;
    width: 50vw;
    height: calc(50vw/1.77)
}

.video iframe {
    width: 100%;
    height: 100%;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

@media only screen and (max-width: 900px) {
    .video {
        object-fit: cover;
        width: 100vw;
        height: calc(100vw/1.77);
    }
}

</style>

<template>

<div class="player-container">
    <lottie-animation path="./lottie/loader.json" class="loader" :width="100" :height="100" />
    <youtube-iframe class="video" ref="yt" :video-id="walk.video_slug" :player-parameters="{ autoplay: 1, start: walk.video_progress }" @ready="onStateChange"></youtube-iframe>
</div>

</template>

<script>

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import {
    ref, onMounted
}
from 'vue'

export default {
    name: 'Video',
    components: {
        LottieAnimation
    },
    props: {
        walk: {
            default: null,
            type: Object
        },
        onUpdate: Function,
    },
    methods: {
        onStateChange(event) {
            //console.log("state change", event);
        }
    },
    watch: {
        walk(value, oldValue) {
            this.$refs.yt.loadVideoById(value.video_slug, 0);
        }
    }
}

function onPlayerReady(event) {
    //console.log(event);
}

function onPlayerStateChange(event) {
    //console.log(event);
}

</script>
