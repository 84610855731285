// router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Stats from '@/views/Stats.vue'
import HiOn from '@/views/HiOn.vue'
import Preview from '@/views/Preview.vue'


var today = new Date();
var release = new Date('2022-05-01 GMT+2')

const routes = (today < release) ?
  [
    {
        path: '/',
        name: 'Preview',
        component: Preview
    }
  ]
 :
  [
      {
          path: '/about',
          name: 'About',
          component: About
      },
      {
          path: '/stats',
          name: 'Stats',
          component: Stats
      },
      {
          path: '/:id?',
          name: 'Home',
          component: Home,
          props: true
      },
      {
          path: '/hi-on',
          name: 'Hi On!',
          component: HiOn
      },
      {
           path: '/video',
           beforeEnter() {location.href = 'https://youtu.be/apjVCmUzlAA'}
      },
      {
           path: '/nft',
           beforeEnter() {location.href = 'https://opensea.io/collection/walked-be'}
      },
      {
           path: '/poster',
           beforeEnter() {location.href = 'https://lepulver.studio/products/strassen-in-bern'}
      }
  ];

const router = createRouter({ history: createWebHistory(), routes })
export default router
