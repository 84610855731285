<style>

#day-of-week-chart {
    max-width: 600px;
    width: 100%;
}

</style>

<template>

<div>
    <svg id="day-of-week-chart"></svg>
</div>

</template>

<script>

import * as d3 from "d3";

export default {
    name: 'DayOfWeekChart',
    props: {
        data: {
            type: [Object],
            default: null
        },
    },
    data() {
        return {
            chart: null
        };
    },
    watch: {
        data(val, oldValue) {
            if (this.chart != null) this.chart.remove();
            this.renderChart(val);
        }
    },
    methods: {
        renderChart(data) {
            // set the dimensions and margins of the graph
            let windowWidth = window.innerWidth > 600 ? 600 : window.innerWidth;

            var margin = {
                top: 30,
                right: 30,
                bottom: 70,
                left: 60
            };
            var width = windowWidth - margin.left - margin.right;
            var height = 400 - margin.top - margin.bottom;

            // date parsing
            var date_format = d3.timeParse("%d.%m.%Y");
            data.forEach(function(d, i) {
                d.dateFormatted = date_format(d.date);
            });

            //var dataByMonth = d3.group(data, function(d) { return d.data.getMonth(); });

            const dayOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            let dayOfWeekFormat = d3.timeFormat("%A");
            let mapByDayOfWeek = d3.rollup(data, v => d3.sum(v, d => d.video_length / 3600), d => dayOfWeekFormat(d.dateFormatted));
            let dataByDayOfWeek = Array.from(mapByDayOfWeek).map(([name, value]) => ({
                name, value
            }))

            // console.log(dataByDayOfWeek);
            // console.log(dataByDayOfWeek.sort((a, b) => {
            //     if (dayOfWeek.indexOf(a.name) > dayOfWeek.indexOf(b.name)) return 1;
            //     if (dayOfWeek.indexOf(a.name) < dayOfWeek.indexOf(b.name)) return -1;
            //     return 0;
            // }));

            var svg = d3.select("#day-of-week-chart")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")");

            // X axis
            var x = d3.scaleBand()
                .range([0, width])
                .domain(dataByDayOfWeek.map((x) => x.name))
                .padding(0.2);


            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x))
                .selectAll("text")
                .attr("transform", "translate(-10,0)rotate(-45)")
                .style("text-anchor", "end");

            // Add Y axis
            var y = d3.scaleLinear()
                .domain([0, d3.max(dataByDayOfWeek, function(d) {
                    return d.value;
                })])
                .range([height, 0]);
            svg.append("g")
                .call(d3.axisLeft(y));

            // Bars
            svg.selectAll("daybar")
                .data(dataByDayOfWeek)
                .enter()
                .append("rect")
                .attr("x", function(d) {
                    return x(d.name);
                })
                .attr("y", function(d) {
                    return y(d.value);
                })
                .attr("width", x.bandwidth())
                .attr("height", function(d) {
                    return height - y(d.value);
                })
                .attr("fill", "#69b3a2");

            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - margin.left)
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Time walked (in h)");

            svg.append("text")
                .attr("transform",
                    "translate(" + (width / 2) + " ," +
                    (height + margin.top + 40) + ")")
                .style("text-anchor", "middle")
                .text("Day of week");
        }
    }
}

</script>
