<style>



</style>

<template>

<article>
    <div>
        <h1>📈 Stats</h1>

        <p>{{ t('stats.1') }}</p>
        <MonthChart :data="data" />

        <hr />
        <p>{{ t('stats.2') }}</p>
        <DayOfWeekChart :data="data" />

        <hr />
        <h2>{{ t('stats.costs.title') }}</h2>
        <p>{{ t('stats.costs.1') }}</p>
        <ul>
          <li><p>{{ t('stats.costs.1-1') }}</p></li>
          <li><p>{{ t('stats.costs.1-2') }}</p></li>
          <li><p>{{ t('stats.costs.1-3') }}</p></li>
          <li><p>{{ t('stats.costs.1-4') }}</p></li>
          <li><p>{{ t('stats.costs.1-5') }}</p></li>
          <li><p>{{ t('stats.costs.1-6') }}</p></li>
       </ul>

       <p>{{ t('stats.costs.2') }}</p>

        <hr />
        <h2>🎥 Video links</h2>
        <ul>
            <li v-for="(item, index) in data" :key="index">
                <p>Walk {{ item.number }} — {{ item.date }} — <a :href="'https://youtube.com/watch?v=' + item.video_slug + '&t=0'" target="_blank">YouTube Link</a></p>
            </li>
        </ul>

    </div>
</article>

</template>

<script>

import { useI18n } from 'vue-i18n'
import MonthChart from '../components/MonthChart.vue'
import DayOfWeekChart from '../components/DayOfWeekChart.vue'
import {
    getFirestore, collection, query, getDocs
}
from "firebase/firestore";

export default {
    name: 'Stats',
    components: {
        MonthChart,
        DayOfWeekChart
    },
    data() {
        return {
            data: []
        }
    },
    setup() {
      const { t } = useI18n({
        inheritLocale: true
      })
      return { t }
    },
    async mounted() {
        const db = getFirestore();
        const docRef = collection(db, "walks");
        const docSnap = await getDocs(docRef);
        const data = docSnap.docs.map((x) => x.data()).sort((a, b) => a.number > b.number ? 1 : -1);
        this.data = data;
    }
}

</script>
