<style>

#month-chart {
    max-width: 600px;
    width: 100%;
}

</style>

<template>

<div>
    <svg id="month-chart"></svg>
</div>

</template>

<script>

import * as d3 from "d3";

export default {
    name: 'MonthChart',
    props: {
        data: {
            type: [Object],
            default: null
        },
    },
    data() {
        return {
            chart: null
        };
    },
    watch: {
        data(val, oldValue) {
            if (this.chart != null) this.chart.remove();
            this.renderChart(val);
        }
    },
    methods: {
        renderChart(data) {
            // set the dimensions and margins of the graph
            let windowWidth = window.innerWidth > 600 ? 600 : window.innerWidth;
            var margin = {
                top: 30,
                right: 30,
                bottom: 70,
                left: 60
            };
            var width = windowWidth - margin.left - margin.right;
            var height = 400 - margin.top - margin.bottom;

            // date parsing
            var date_format = d3.timeParse("%d.%m.%Y");
            data.forEach(function(d, i) {
                d.dateFormatted = date_format(d.date);
            });

            //var dataByMonth = d3.group(data, function(d) { return d.data.getMonth(); });

            let monthFormat = d3.timeFormat("%B");
            let mapByMonth = d3.rollup(data, v => d3.sum(v, d => d.video_length / 3600), d => monthFormat(d.dateFormatted));
            let dataByMonth = Array.from(mapByMonth).map(([name, value]) => ({
                name, value
            }));


            var svg = d3.select("#month-chart")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")");

            // X axis
            var x = d3.scaleBand()
                .range([0, width])
                .domain(dataByMonth.map((x) => x.name))
                .padding(0.2);


            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x))
                .selectAll("text")
                .attr("transform", "translate(-10,0)rotate(-45)")
                .style("text-anchor", "end");

            // Add Y axis
            var y = d3.scaleLinear()
                .domain([0, d3.max(dataByMonth, function(d) {
                    return d.value;
                })])
                .range([height, 0]);
            svg.append("g")
                .call(d3.axisLeft(y));

            // Bars
            svg.selectAll("mybar")
                .data(dataByMonth)
                .enter()
                .append("rect")
                .attr("x", function(d) {
                    return x(d.name);
                })
                .attr("y", function(d) {
                    return y(d.value);
                })
                .attr("width", x.bandwidth())
                .attr("height", function(d) {
                    return height - y(d.value);
                })
                .attr("fill", "#69b3a2");

            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - margin.left)
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Time walked (in h)");

            svg.append("text")
                .attr("transform",
                    "translate(" + (width / 2) + " ," +
                    (height + margin.top + 20) + ")")
                .style("text-anchor", "middle")
                .text("Month");
        }
    }
}

</script>
