<style>
.nft {
  display: flex;
  gap: 3px;
}

.nft span {
  display: flex;
  gap: 3px;
}
</style>

<template>

<div v-if="asset != null" class="nft">
  <a :href="asset.permalink" target="_blank">
      <p>NFT 🌈</p>
  </a>
  <span v-if="asset.last_sale !== undefined && asset.last_sale !== null">
  <p>owned by</p>
  <a :href="'https://opensea.io/' + asset.last_sale.transaction.from_account.address"  target="_blank">

    <p v-if="asset.last_sale.transaction.from_account.user.username !== null">
    {{ asset.last_sale.transaction.from_account.user.username }}</p>
    <p v-else>{{ asset.last_sale.transaction.from_account.address.substring(0,8) }}...</p>
  </a>
</span>
</div>

</template>

<script>

import {
    ref, computed, onMounted
}
from "vue";

import OpenseaAPI from '../api/Opensea.js';

export default {
    name: 'Opensea',
    props: {
        id: {
            type: String,
            default: null
        }
    },
    setup(props, context) {
        const asset = ref({});
        const loadAsset = async() => {
            asset.value = await OpenseaAPI.index(props.id);
        };

        return {
            asset,
            loadAsset,
        }
    },
    mounted() {
        this.loadAsset();
    },
    watch: {
      id(value, oldValue) {
        this.loadAsset();
      }
    }
}

</script>
