export default {
  index(id){
      return fetch("https://api.opensea.io/api/v1/asset/" + id + "?format=json", {
          method: 'GET'
      } )
      .then( function( response ){
          if( response.status != 200 ){
              throw response.status;
          }else{
              return response.json();
          }
      });
  },

}
