<style>

</style>

<template>

<article>
    <div>
        <h1>Hi On! 👋</h1>

        <img src="../assets/hi-on.jpg" alt="Hi On" />

        <p>My name is Yannick. In the end of January 2021 I had the idea to “Walk through every street in Bern, Switzerland” 🏃. 239 days later and I finished my goal! I have walked a total of 436.76km in 77.4 hours over a total
            of 107 walks. You can find everything about the project on this website and also soon in a video!</p>

        <p>Before the project, I bought a brand new pair of <a href="https://www.on-running.com/de-de/products/cloud-classic/mens/all-black">On Running Cloud</a>. They worked absolutely great for the project and were not my first pair! But yeah, as you might see, after the project they're all done!</p>

        <p>Do you think you can do anything about that? 👀</p>

        <p>I'd happily mention you in my upcoming video aswell!</p>

        <p>All the best from Bern, Yannick</p>

    </div>
</article>

</template>

<script>

export default {
    name: 'About'
}

</script>
