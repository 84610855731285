<style>
.background-image {
  background: url('~@/assets/blur.jpg');
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

article.preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

article.preview div {
  background: #fff;
  padding: 24px;
  margin: 24px;
  box-shadow: 1px 1px 16px #00000014;
}

.bold {
  color: #2995d9;
}

.smooth a {
  color: #848484;
  text-decoration: none;
}


</style>

<template>
  <div class="background-image" />

<article class="preview">
    <div>
        <h1>walked.be</h1>

        <p>Hi there 👋. My little / not so little project about walking every street in Bern, Switzerland ist launching on <span class="bold">1st of May 2022</span>. Hope to see you back then.</p>

        <p>
        👉 <vue-countdown :time="time" v-slot="{ days, hours, minutes, seconds }">
        {{ days }} days, {{ hours }} hours, {{ minutes }} minutes, {{ seconds }} seconds.
      </vue-countdown>
    </p>

  <p>Best, <span class="smooth"><a href="https://yannickpulver.com" target="_blank">Yannick</a></span>.</p>
    </div>
</article>


</template>

<script>

var today = new Date();
var release = new Date('2022-05-01 GMT+2')


export default {
    name: 'Preview',
    data() {
        return {
            time: 0,
        }
    },
    mounted() {
      this.time = release.getTime() - today.getTime();
    },
}

</script>
