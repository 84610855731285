import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueMapboxTs from "vue-mapbox-ts";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import YoutubeIframe from '@techassi/vue-youtube-iframe';
import VueCountdown from '@chenfengyuan/vue-countdown';
import i18n from './i18n'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVdCjYHLtIgCCaDFrdO1Mmvl9CABc2wMg",
  authDomain: "walked-be.firebaseapp.com",
  projectId: "walked-be",
  storageBucket: "walked-be.appspot.com",
  messagingSenderId: "348615238228",
  appId: "1:348615238228:web:051cc3716ae49e7d5483c1",
  measurementId: "G-G3VRG21QXS"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);


const app = createApp(App).use(i18n)

app.component(VueCountdown.name, VueCountdown);

app
  .use(VueMapboxTs)
  .use(firebaseApp)
  .use(YoutubeIframe)
  .use(router)
  .mount('#app')
